import React from 'react'
import {graphql} from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismic-previews'
import { RichText } from 'prismic-reactjs'
import { SliceZones } from '../components/slices/slice-zones'
import Layout from '../components/global/layout'
import CustomLink from '../utils/prismic-content-link'
import {siteMetadata} from '../site-config'


const JobDescription = ({ data, pageContext }) => {
    if (!data)
        return null

    const document = data.prismicJobDescription

    return (
    <Layout pageTitle={document.data.title.text}
            metaTitle={document.data.meta_title.text}
            metaDescription={document.data.meta_description.text || document.data.summary.text}
            shareImage={document.data.share_image.url}>
      <div className="c-article">
        <div className="container">
            <div className="row">
                <div className="c-article__main">
                    <div className="c-article__header">
                        <h1 className="c-article__header-title">{document.data.title.text}</h1>
                    </div>
                    <div className="c-article__body cms-content">
                      <RichText render={document.data.content.raw} serializeHyperlink={CustomLink} />
                    </div>
                </div>
                <div className="c-article__sidebar">
                    <div className="c-article__apply">
                        <div className="c-article__subscribe-title" id="share-label">Apply</div>
                        <br/>
                        <p className="c-contact__form-consent">Send a two-page CV to <a target="_blank" href={`mailto:${ siteMetadata.email.careers }?subject=${ document.data.title.text } job application`} rel="noreferrer">{siteMetadata.email.careers}</a> highlighting your suitability for this role.</p>
                        <a href={`mailto:${ siteMetadata.email.careers }?subject=${ document.data.title.text } job application`} rel="noreferrer" target="_blank" className="c-contact__submit c-btn c-btn--large c-btn--primary">Apply now</a>
                    <div>
                </div>
            </div>
        </div>
            </div>
        </div>
      </div>
      <SliceZones slices={document.data.body} />
    </Layout>
    )
}

export const query = graphql`
  query JobDescriptionQuery($id: String) {
    prismicJobDescription(id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        title {
          text
        }
        summary {
          text
        }
        content {
          raw
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        share_image {
          url
        }
        body {
          ... on PrismicJobDescriptionDataBodyLogos {
            id
            items {
              logos_image {
                alt
                copyright
                url
                gatsbyImageData
              }
              logos_name {
                text
                html
                raw
              }
            }
            primary {
              logos_title {
                text
              }
              logos_background_image {
                url
              }
            }
            slice_type
          }
          ... on PrismicJobDescriptionDataBodyContentWithMedia {
            id
            primary {
              contentmedia_subtitle {
                text
              }
              contentmedia_title {
                text
              }
              contentmedia_content {
                text
              }
              contentmedia_image {
                url
              }
              contentmedia_button_text {
                text
              }
              contentmedia_button_link {
                url
              }
              contentmedia_button2_text {
                text
              }
              contentmedia_button2_link {
                url
              }
              contentmedia_image_alignment
            }
            slice_type
          }
          ... on PrismicJobDescriptionDataBodyCallToAction {
            id
            primary {
              cta_button_link {
                url
              }
              cta_button_text
              cta_description {
                text
              }
              cta_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicJobDescriptionDataBodyRichTextContent {
            id
            primary {
              richtextcontent_content {
                raw
              }
            }
            slice_type
          }
          ... on PrismicJobDescriptionDataBodyTestimonial {
            id
            primary {
              testimonial_quote {
                text
              }
              testimonial_link_text {
                text
              }
              testimonial_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicJobDescriptionDataBodyWhyWorkWithUs {
            id
            primary {
              whyworkwithus_point1 {
                text
              }
              whyworkwithus_point2 {
                text
              }
              whyworkwithus_point3 {
                text
              }
              whyworkwithus_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicJobDescriptionDataBodyUseCases {
            id
            items {
              usecases_usecase {
                text
              }
            }
            primary {
              usecases_link_text {
                text
              }
              usecases_title {
                text
              }
              usescases_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicJobDescriptionDataBodyTestimonialCarousel {
            id
            items {
              testimonialcarousel_author {
                text
              }
              testimonialcarousel_authorjobtitle {
                text
              }
              testimonialcarousel_image {
                url
              }
              testimonialcarousel_quote {
                text
              }
              testimonialcarousel_title {
                text
              }
            }
            primary {
              testimonialcarousel_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicJobDescriptionDataBodyHowItWorksCarousel {
            id
            primary {
              howitworkscarousel_title {
                text
              }
            }
            items {
              howitworkscarousel_title {
                text
              }
              howitworkscarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicJobDescriptionDataBodyFeaturedProjects {
            id
            primary {
              featuredprojects_startprojectlink {
                url
              }
              featuredprojects_title {
                text
              }
              display_tag
            }
            items {
              featuredprojects_document {
                document {
                  ... on PrismicProject {
                    id
                    data {
                      image {
                        url
                      }
                      summary {
                        text
                      }
                      title {
                        text
                      }
                      industry
                      service
                    }
                    tags
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicJobDescriptionDataBodyServicesCarousel {
            id
            items {
              servicescarousel_image {
                url
              }
              servicescarousel_circleimage {
                url
              }
              servicescarousel_fullscreenimage {
                url
              }
              servicescarousel_link {
                url
              }
              servicescarousel_navigationtitle {
                text
              }
              servicescarousel_summary {
                text
              }
              servicescarousel_title {
                text
              }
            }
            primary {
              servicescarousel_subtitle {
                text
              }
              servicescarousel_title {
                text
              }
              servicescarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicJobDescriptionDataBodyFeaturedBlogPosts {
            id
            items {
              featuredblogposts_document {
                document {
                  ... on PrismicInsightArticle {
                    id
                    data {
                      listing_image {
                        url
                      }
                      title {
                        text
                      }
                      summary {
                        text
                      }
                    }
                    url
                    tags
                  }
                }
                type
              }
            }
            primary {
              featuredblogposts_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicJobDescriptionDataBodyListing {
            id
            primary {
              listing_title {
                text
              }
              listing_buttonlink {
                url
              }
              listing_buttontext {
                text
              }
              listing_button2link {
                url
              }
              listing_button2text {
                text
              }
            }
            items {
              image {
                url
              }
              listing_title {
                text
              }
            }
            slice_type
          }
        }
      }
    }
  }
`

export default withPrismicPreview(JobDescription, repositoryConfigs)